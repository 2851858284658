import React, { useState } from "react";
import useUserStore from "../../../../services/userStore";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { awsIcons, azureIcons } from "../../AWS/AWSIcons";
import moment from "moment";

const AccessVMResources = ({
  publishedVMInstances,
  setPublishedVMInstances,
  user,
}) => {
  const { role, userId } = useUserStore((state) => state.user);
  const [selectedResource, setSelectedResource] = useState(null);
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "18px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Synced At</TableCell>
              {role === "TENANT_USER" ? <TableCell>Action</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {publishedVMInstances?.length > 0 ? (
              publishedVMInstances.map((vm, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {azureIcons.vm}
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {vm.name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {vm.region}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {moment(vm.syncedAt).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    {role === "TENANT_USER" ? (
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {}}
                        >
                          Request Access
                        </Button>
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    No VMS available.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccessVMResources;
