import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";

import { AuthProvider } from "./contexts/JWTContext";

import useUserStore from "./services/userStore";

import { myLocalStorage } from "./components/StorageHelper";
import { QueryClient, QueryClientProvider } from "react-query";
import UserService from "./services/UserService";
import {
  checkIfTokenExpired,
  decodeKeycloakToken,
  refreshAccessToken,
  sendTokenToBackend,
  storeLatestTenant,
} from "./utils/tokenHelpers";
import { clearLocalStorageOnLogout, usersRoutes } from "./services/Helpers";
import { channel, logOut } from "./services/Auth";
import { Alert, Snackbar } from "@mui/material";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const App = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code") || "";
  const isFromAzureSSO = params.get("fromAzureSSO") || false;

  const navigate = useNavigate();
  const { theme } = useTheme();
  const [servererror, setServerError] = useState(false);

  const location = useLocation();

  const content = useRoutes(routes);
  const {
    user: userData,
    getUserData: fetchUserData,
    azureRedirectURI,
    email,
  } = useUserStore((state) => state);

  const lastVisitedPage = myLocalStorage.getItem("lastVisitedPage");
  const access_token =
    UserService?.getToken() || myLocalStorage.getItem("access_token");

  const realm = myLocalStorage.getItem("realm");

  const logOutDueToTokenExpire = () => {
    console.log("APP component logout");
    const email = myLocalStorage.getItem("email");
    return logOut(email)
      .then(() => {
        clearLocalStorageOnLogout();
        navigate("/signIn");
      })
      .catch((error) => {
        console.error("An error occurred during logout:", error);
      });
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    const allowedPaths = [
      "/",
      "/signUp",
      "/signIn",
      "null",
      "/terms_of_service",
      "/loading",
      "/404",
    ];
    const currentPath = location.pathname;
    console.log(currentPath);

    if (!allowedPaths.includes(currentPath)) {
      myLocalStorage.setItem("lastVisitedPage", currentPath);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (access_token) {
      let userEmail = "";
      if (myLocalStorage.getItem("azuresso")) {
        userEmail = myLocalStorage.getItem("email");
      } else {
        const decodedToken = decodeKeycloakToken(access_token);
        userEmail = decodedToken.email;
      }
      fetchUserData(userEmail);
    }
  }, [access_token, fetchUserData]);

  useEffect(() => {
    const navigateOnRefresh = async (user) => {
      if (user.role !== "TENANT_USER") {
        navigate(lastVisitedPage ? lastVisitedPage : "/dashboard/overview");
      } else {
        navigate(
          lastVisitedPage && usersRoutes.includes(lastVisitedPage)
            ? lastVisitedPage
            : "/endpoints",
        );
      }
    };

    if (userData?.role && access_token) {
      navigateOnRefresh(userData);
      storeLatestTenant();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (isFromAzureSSO) {
      const email = myLocalStorage.getItem("email");
      console.log(code, email);
      if (!access_token && code) {
        myLocalStorage.setItem("azuresso", true);
        UserService.exchangeCodeForToken(code, email);
      }
    } else if (!access_token && realm) {
      myLocalStorage.removeItem("azuresso");
      UserService.initKeycloak(realm);
    }
  }, [access_token, realm, code, isFromAzureSSO]);

  useEffect(() => {
    const logoutMultipleTabs = () => {
      channel.onmessage = () => {
        navigate("/signIn");
      };
    };
    logoutMultipleTabs();
  }, [navigate]);

  axios.interceptors.request.use(
    (request) => {
      const access_token = myLocalStorage.getItem("access_token");
      setServerError(false);
      if (access_token) {
        request.headers.Authorization = `Bearer ${access_token}`;
      }

      return request;
    },
    (error) => {
      setServerError(true);
      console.error(error);
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    async (response) => {
      if (response?.status === 401) {
        console.log("You are not authorized");
      }
      return response;
    },
    async (error) => {
      // if (axios.isCancel(error)) {
      //   console.log("Request canceled:", error.message);
      //   return Promise.reject(error);
      // }

      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const access_token = myLocalStorage.getItem("access_token");
        if (!access_token) navigate("/signIn");

        try {
          const email = myLocalStorage.getItem("email");
          let role = myLocalStorage.getItem("role");
          const realm = myLocalStorage.getItem("realm");
          if (myLocalStorage.getItem("azuresso")) role = "TENANT_USER";

          const stored_refresh_token = myLocalStorage.getItem("refresh_token");
          const isRefreshTokenExpired =
            checkIfTokenExpired(stored_refresh_token);

          if (isRefreshTokenExpired) {
            logOutDueToTokenExpire();
            return;
          } else {
            console.log("Refresh Token is active");
          }
          const { access_token, refresh_token } = await refreshAccessToken();

          let decode = "";
          if (myLocalStorage.getItem("azuresso")) {
            const azureAccessToken = myLocalStorage.getItem("access_token");
            decode = decodeKeycloakToken(azureAccessToken);
          } else {
            decode = decodeKeycloakToken(access_token);

            myLocalStorage.setItem("access_token", access_token);
          }
          let { exp } = decode;
          myLocalStorage.setItem("refresh_token", refresh_token);
          await sendTokenToBackend(realm, email, role, access_token, exp);

          originalRequest.headers.Authorization = `Bearer ${access_token}`;
          setServerError(false);
          return axios(originalRequest);
        } catch (refreshTokenError) {
          setServerError(true);
          console.error(refreshTokenError);
          return Promise.reject(refreshTokenError);
        }
      } else {
        setServerError(true);
        console.error("Error occurred:", error);
      }
      return Promise.reject(error);
    },
  );

  return (
    <>
      {/* {servererror ? (
        <Snackbar
          open={servererror}
          autoHideDuration={6000}
          onClose={() => setServerError(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setServerError(false)}
            severity={"error"}
            sx={{ width: "100%" }}
          >
            Something went wrong. Try again later.
          </Alert>
        </Snackbar>
      ) : null} */}
      {userData?.loading ? (
        <p>loading</p>
      ) : (
        <HelmetProvider>
          <Helmet
            titleTemplate="%s | White Swan Security"
            defaultTitle="White Swan Security"
          />
          {/* <meta
              httpEquiv="Content-Security-Policy"
              // content={`
              //         default-src 'self';
              //         script-src 'self';
              //         style-src 'self' 'unsafe-inline';
              //         connect-src 'self' ${NGROK} ${authNGROK};
              //         frame-src 'self' ${NGROK} ${authNGROK};
              //   `}
            ></meta>
          </Helmet> */}
          <QueryClientProvider client={queryClient}>
            <StylesProvider jss={jss}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledEngineProvider injectFirst>
                  <MuiThemeProvider theme={createTheme(theme)}>
                    <ThemeProvider theme={createTheme(theme)}>
                      <AuthProvider>{content}</AuthProvider>
                    </ThemeProvider>
                  </MuiThemeProvider>
                </StyledEngineProvider>
              </LocalizationProvider>
            </StylesProvider>
          </QueryClientProvider>
        </HelmetProvider>
      )}
    </>
  );
};

export default App;
