import React, { useState } from "react";
import { myLocalStorage } from "../../../../components/StorageHelper";
import {
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { NGROK } from "../../../../APIs";
import axios from "axios";
import moment from "moment";
import RequestMessageDialog from "../../../components/RequestMessageDialog";

const RequestsAzureTableBody = ({
  adminNotifications,
  fromdetails,
  computerName,
}) => {
  const [loadingRequests, setLoadingRequests] = useState({});

  const tenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const handleRequest = async (
    id,
    status,
    fromdetails,
    computerName,
    notification,
  ) => {
    try {
      setLoadingRequests((prev) => ({ ...prev, [id]: true }));

      await axios.put(`${NGROK}/api/aws-request-access?tenantName=${tenant}`, {
        accessId: id,
        requestStatus: status,
        accessType: notification.accessType,
        arn: notification.accessARN,
        name: notification.accessName,
        userName: notification.awsUsername,
        policy: notification.selectedPolicies,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRequests((prev) => ({ ...prev, [id]: false }));
    }
  };
  return (
    <>
      <TableBody>
        {adminNotifications.length ? (
          adminNotifications.map((notification) => (
            <TableRow key={notification.id}>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {moment(notification.createdAt).format("YYYY/MM/DD/HH:mm")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="left"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {notification.email}
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {notification.accessType}
                </Typography>
              </TableCell>

              <TableCell
                sx={{
                  fontSize: "16px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  maxWidth: "350px",
                }}
                align="center"
              >
                <Typography fontSize={16} variant="subtitle2">
                  {notification.selectedPolicies !== null
                    ? notification.selectedPolicies
                    : "-"}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
              >
                <RequestMessageDialog
                  messageText={notification.requestMessage}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                }}
                align="center"
              >
                {notification.privilegeLevel !== "DYNAMIC" ? (
                  <Typography
                    fontWeight={500}
                    fontSize={16}
                    variant="subtitle2"
                  >
                    PERMANENT
                  </Typography>
                ) : (
                  <Stack spacing={2}>
                    <Typography
                      fontWeight={500}
                      fontSize={16}
                      variant="subtitle2"
                    >
                      DYNAMIC
                    </Typography>
                    <Typography
                      fontWeight={500}
                      fontSize={16}
                      variant="subtitle2"
                    >
                      {notification.selectedTime} min
                    </Typography>
                  </Stack>
                )}
              </TableCell>
              {notification.requestStatus === "PENDING" ? (
                <>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    <Button
                      onClick={() => {
                        handleRequest(
                          notification.id,
                          "APPROVED",
                          fromdetails,
                          computerName,
                          notification,
                        );
                      }}
                      variant="outlined"
                      disabled={loadingRequests[notification.id]}
                    >
                      Approve
                    </Button>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                    }}
                    align="center"
                  >
                    <Button
                      onClick={() => {
                        handleRequest(
                          notification.id,
                          "DECLINE",
                          fromdetails,
                          computerName,
                          notification,
                        );
                      }}
                      color="error"
                      variant="outlined"
                      disabled={loadingRequests[notification.id]}
                    >
                      Decline
                    </Button>
                  </TableCell>
                </>
              ) : notification.requestStatus === "APPROVED" ? (
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  <Button color="success" variant="outlined">
                    APPROVED
                  </Button>
                </TableCell>
              ) : notification.requestStatus === "DECLINE" ? (
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  <Button color="error" variant="outlined">
                    DECLINED
                  </Button>
                </TableCell>
              ) : (
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "16px",
                  }}
                  align="center"
                >
                  <Button color="error" variant="outlined">
                    EXPIRED
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={10}
              sx={{
                fontSize: "16px",
              }}
              align="center"
            >
              <Typography fontWeight={500} fontSize={16} variant="subtitle2">
                No Resource Request found
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </>
  );
};

export default RequestsAzureTableBody;
