import React from "react";

const AccessDBInstances = ({
  publishedDBInstances,
  setPublishedDBInstances,
  user,
}) => {
  return <div></div>;
};

export default AccessDBInstances;
