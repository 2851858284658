import React, { useEffect, useState } from "react";

import RequestsTable from "./Components/RequestsTable";
import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import useNotificationStore from "../../../services/notificationStore";
import SearchField from "../logs/components/SearchField";
import CustomDatePicker from "../logs/components/CustomDatePicker";
import useDateFilter from "../../../hooks/useDateFilter";
import { handleGeneratePDF } from "./helpers";
import TenantSelection from "../Components/TenantSelection";
import { myLocalStorage } from "../../../components/StorageHelper";
import axios from "axios";
import { NGROK } from "../../../APIs";
import { TabContext, TabList } from "@mui/lab";
import RequestsAWSTable from "./Components/RequestsAWSTable";
import useUserStore from "../../../services/userStore";
import RequestAzureTable from "./Components/RequestAzureTable";

const ResourceRequests = ({ fromdetails = false, computerName = null }) => {
  const [tabValue, setTabValue] = useState("Whiteswan");
  const [awsNotifications, setAWSNotifications] = useState([]);
  const userData = useUserStore((state) => state.user);
  let userRole = !userData.userRole ? "" : userData.userRole;
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const tenant = myLocalStorage.getItem("latestTenant");

  const { adminNotifications, setAdminNotifications, adminawsNotifications } =
    useNotificationStore();
  const [selectedTenant, setSelectedTenant] = useState(tenant);

  const allowedSearchFields = [
    "createdAt",
    "email",
    "computerName",
    "resourcePath",
    "requestStatus",
    "privilegeLevel",
  ];
  const {
    searchTerm,
    filteredData: notificationsFilteredBySearch,
    handleSearch,
  } = useGeneralSearch(adminNotifications, allowedSearchFields);

  const {
    dateRange,
    handleChangeDateRange,
    filteredData: filteredNotifications,
  } = useDateFilter(notificationsFilteredBySearch, "createdAt");

  const tenantNotifications =
    filteredNotifications?.filter(
      (notification) => notification.tenantName === selectedTenant?.tenantName,
    ) || [];

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {!fromdetails ? (
          <TenantSelection
            selectedTenant={selectedTenant}
            setSelectedTenant={setSelectedTenant}
          />
        ) : (
          <Box></Box>
        )}

        <Stack direction={"row"} spacing={2}>
          <CustomDatePicker
            dateRange={dateRange}
            handleChangeDateRange={handleChangeDateRange}
          />

          <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />

          <Button
            variant="outlined"
            onClick={() =>
              handleGeneratePDF(dateRange, tenantNotifications, searchTerm)
            }
            disabled={tenantNotifications.length === 0}
          >
            Download Report
          </Button>
        </Stack>
      </Box>

      <Box sx={{ width: "100%", typography: "body1" }}>
        {fromdetails || userRole === "epam-admin" ? (
          <RequestsTable
            adminNotifications={tenantNotifications}
            setAdminNotifications={setAdminNotifications}
            fromdetails={fromdetails}
            computerName={computerName}
          />
        ) : (
          <TabContext value={tabValue}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              display={"flex"}
              justifyContent={"center"}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="onPrem" value={"Whiteswan"} />
                <Tab label="AWS" value={"AWS"} />
                <Tab label="Azure" value={"Azure"} />
              </TabList>
            </Box>
            <CustomTabPanel value={tabValue} index={"Whiteswan"}>
              <RequestsTable
                adminNotifications={tenantNotifications}
                setAdminNotifications={setAdminNotifications}
                fromdetails={fromdetails}
                computerName={computerName}
                tenantName={selectedTenant?.tenantName}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={"AWS"}>
              <RequestsAWSTable
                adminNotifications={adminawsNotifications}
                fromdetails={fromdetails}
                computerName={computerName}
              />
            </CustomTabPanel>

            <CustomTabPanel value={tabValue} index={"Azure"}>
              <RequestAzureTable
                adminNotifications={[]}
                fromdetails={fromdetails}
                computerName={computerName}
              />
            </CustomTabPanel>
          </TabContext>
        )}
      </Box>
    </Stack>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default ResourceRequests;
