import {
  Groups as GroupsIcon,
  Group as GroupIcon,
  Security as SecurityIcon,
  AccountCircle as AccountCircleIcon,
  CloudCircle as Ec2Icon,
  Storage as StorageIcon,
  Shield as ShieldIcon,
  Cloud as CloudIcon,
  Person as PersonIcon,
  Policy as PolicyIcon,
} from "@mui/icons-material";
import { FaDatabase } from "react-icons/fa6";
import { SiMysql } from "react-icons/si";
import { BiLogoPostgresql } from "react-icons/bi";
import { SiMariadb } from "react-icons/si";
import { PiFileSqlLight } from "react-icons/pi";
import { SiIbm } from "react-icons/si";
import { VscVmConnect } from "react-icons/vsc";

export const awsIcons = {
  groups: <GroupsIcon />,
  group: <GroupIcon />,
  policies: <SecurityIcon />,
  policy: <PolicyIcon />,
  iam: <AccountCircleIcon />,
  user: <PersonIcon />,
  instances: <Ec2Icon />,
  buckets: <StorageIcon />,
  roles: <ShieldIcon />,
  resources: <CloudIcon />,
  rdsLarge: (
    <FaDatabase
      style={{
        width: "2em",
        height: "2em",
      }}
    />
  ),
  rds: (
    <FaDatabase
      style={{
        width: "1.2em",
        height: "1.2em",
      }}
    />
  ),
  sqlServer: (
    <PiFileSqlLight
      style={{
        width: "1.5em",
        height: "1.5em",
      }}
    />
  ),
  mysql: (
    <SiMysql
      style={{
        width: "1.5em",
        height: "1.5em",
      }}
    />
  ),
  psql: (
    <BiLogoPostgresql
      style={{
        width: "1.5em",
        height: "1.5em",
      }}
    />
  ),
  maria: (
    <SiMariadb
      style={{
        width: "1.5em",
        height: "1.5em",
      }}
    />
  ),
  ibm: (
    <SiIbm
      style={{
        width: "1.5em",
        height: "1.5em",
      }}
    />
  ),
  oracle: (
    <FaDatabase
      style={{
        width: "1.5em",
        height: "1.5em",
      }}
    />
  ),
};

export const azureIcons = {
  vm: (
    <VscVmConnect
      style={{
        width: "2em",
        height: "2em",
      }}
    />
  ),
  strgs: <StorageIcon />,
  database: (
    <FaDatabase
      style={{
        width: "2em",
        height: "2em",
      }}
    />
  ),
};
